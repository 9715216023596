var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h4',{staticClass:"border-left-primary pl-50",staticStyle:{"border-left-width":"4px"}},[_vm._v(" "+_vm._s("Thêm thành viên")+" ")])]},proxy:true}])},[_c('div',[_c('validation-observer',{ref:"addPartnerUserValidation"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2 required",attrs:{"label":_vm.$t('Chọn thành viên')}},[_c('validation-provider',{attrs:{"name":_vm.$t('Chọn thành viên'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"placeholder":_vm.$t('Chọn thành viên'),"label":"name","clearable":false,"filterable":false,"options":_vm.listMembers || []},on:{"open":_vm.onOpen,"close":_vm.onClose,"input":_vm.handleChangeMember,"search":_vm.handleDebounce},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"24"}})],1)]}},{key:"list-footer",fn:function(){return undefined},proxy:true},{key:"no-options",fn:function(){return [_vm._v(_vm._s(_vm.$t("lbl_no_data")))]},proxy:true}],null,true),model:{value:(_vm.selectedMember),callback:function ($$v) {_vm.selectedMember=$$v},expression:"selectedMember"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"mb-2 required",attrs:{"label":_vm.$t('Chọn vai trò')}},[_c('validation-provider',{attrs:{"name":_vm.$t('Chọn vai trò'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"placeholder":_vm.$t('Chọn vai trò'),"label":"role_description","clearable":false,"filterable":false,"options":_vm.listRoles || []},on:{"open":_vm.onOpen,"close":_vm.onClose,"input":_vm.handleChangeRole,"search":_vm.handleDebounce},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
var attributes = ref.attributes;
return [_c('span',_vm._b({},'span',attributes,false),[_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","size":"24"}})],1)]}},{key:"no-options",fn:function(){return [_vm._v(_vm._s(_vm.$t("lbl_no_data")))]},proxy:true},{key:"option",fn:function(ref){
var role_description = ref.role_description;
var role_summary = ref.role_summary;
return [_c('b',{staticClass:"d-block"},[_vm._v(_vm._s(role_description))]),_c('small',{staticClass:"d-block fs-12"},[_c('em',[_vm._v(_vm._s(role_summary))])])]}}],null,true),model:{value:(_vm.selectedRole),callback:function ($$v) {_vm.selectedRole=$$v},expression:"selectedRole"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{staticClass:"mb-2",attrs:{"label":_vm.$t('Quyền hạn của vai trò được chọn')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-checkbox-group',{staticClass:"d-flex flex-wrap gap-3 pointer-events-none cursor-default",attrs:{"id":"checkbox-partner-permissions","options":_vm.listPermissions,"aria-describedby":ariaDescribedby,"value-field":"permission_id","text-field":"permission_info.description","name":"partner-permissions","switches":"","disabled":true},on:{"input":_vm.handleChangePermission},model:{value:(_vm.selectedPermissions),callback:function ($$v) {_vm.selectedPermissions=$$v},expression:"selectedPermissions"}})]}}])})],1),_c('b-col',{staticClass:"d-flex justify-content-end",attrs:{"md":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"ml-1",attrs:{"variant":"outline-primary","disabled":_vm.isLoading || _vm.isSubmitting},on:{"click":function($event){$event.stopPropagation();return _vm.resetAddForm($event)}}},[_c('span',[_vm._v(_vm._s(_vm.$t("lbl_reset_data")))])]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"ml-1",attrs:{"variant":"primary","disabled":_vm.isLoading || _vm.isSubmitting},on:{"click":function($event){$event.stopPropagation();return _vm.addPartnerUser($event)}}},[_c('span',[_vm._v(_vm._s(_vm.$t("lbl_add_new")))])])],1)],1)],1)],1)],1)]),_c('b-card',{attrs:{"no-body":""}},[_c('div',{staticClass:"px-2 pt-2"},[_c('h4',{staticClass:"border-left-primary pl-50",staticStyle:{"border-left-width":"4px"}},[_vm._v(" "+_vm._s("Danh sách thành viên")+" ")])]),_c('TableListPartnerUsers',{attrs:{"count":_vm.reloadCount}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }