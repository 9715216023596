<template>
  <div>
    <b-card>
      <template #header>
        <h4 class="border-left-primary pl-50" style="border-left-width: 4px">
          {{ "Thêm thành viên" }}
        </h4>
      </template>
      <div>
        <validation-observer ref="addPartnerUserValidation">
          <b-form>
            <b-row>
              <b-col md="6">
                <b-form-group :label="$t('Chọn thành viên')" class="mb-2 required">
                  <validation-provider #default="{ errors }" :name="$t('Chọn thành viên')" rules="required">
                    <v-select :placeholder="$t('Chọn thành viên')" v-model="selectedMember" label="name"
                      :clearable="false" :filterable="false" :options="listMembers || []" @open="onOpen"
                      @close="onClose" @input="handleChangeMember" @search="handleDebounce">
                      <template #open-indicator="{ attributes }">
                        <span v-bind="attributes">
                          <feather-icon icon="ChevronDownIcon" size="24" />
                        </span>
                      </template>
                      <template #list-footer>
                        <!-- <li v-show="hasMemberNextPage" ref="loadInfinity" class="text-center">
                          <span>
                            <b-icon class="align-middle" icon="arrow-repeat" animation="spin" font-scale="1.2"></b-icon>
                            <span class="align-middle">{{
                              $t("Đang tải")
                              }}</span>
                          </span>
                        </li> -->
                      </template>
                      <template #no-options>{{ $t("lbl_no_data") }}</template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group :label="$t('Chọn vai trò')" class="mb-2 required">
                  <validation-provider #default="{ errors }" :name="$t('Chọn vai trò')" rules="required">
                    <v-select :placeholder="$t('Chọn vai trò')" v-model="selectedRole" label="role_description"
                      :clearable="false" :filterable="false" :options="listRoles || []" @open="onOpen" @close="onClose"
                      @input="handleChangeRole" @search="handleDebounce">
                      <template #open-indicator="{ attributes }">
                        <span v-bind="attributes">
                          <feather-icon icon="ChevronDownIcon" size="24" />
                        </span>
                      </template>
                      <template #no-options>{{ $t("lbl_no_data") }}</template>
                      <template #option="{ role_description, role_summary }">
                        <b class="d-block">{{ role_description }}</b>
                        <small class="d-block fs-12"><em>{{ role_summary }}</em></small>
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group :label="$t('Quyền hạn của vai trò được chọn')" class="mb-2" v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group id="checkbox-partner-permissions"
                    class="d-flex flex-wrap gap-3 pointer-events-none cursor-default" v-model="selectedPermissions"
                    :options="listPermissions" :aria-describedby="ariaDescribedby" value-field="permission_id"
                    text-field="permission_info.description" name="partner-permissions" switches :disabled="true"
                    @input="handleChangePermission"></b-form-checkbox-group>
                </b-form-group>
              </b-col>
              <b-col md="12" class="d-flex justify-content-end">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="ml-1" variant="outline-primary"
                  :disabled="isLoading || isSubmitting" @click.stop="resetAddForm">
                  <span>{{ $t("lbl_reset_data") }}</span>
                </b-button>
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="ml-1" variant="primary"
                  :disabled="isLoading || isSubmitting" @click.stop="addPartnerUser">
                  <span>{{ $t("lbl_add_new") }}</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </b-card>
    <b-card no-body>
      <div class="px-2 pt-2">
        <h4 class="border-left-primary pl-50" style="border-left-width: 4px">
          {{ "Danh sách thành viên" }}
        </h4>
      </div>
      <TableListPartnerUsers :count="reloadCount" />
    </b-card>
  </div>
</template>

<script>
import appUtils from "@/utils/appUtils";
import { required } from "@validations";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import TableListPartnerUsers from "./TableListPartnerUsers.vue";
import useActionsConfigRoles from "./useActionsConfigRoles";

export default {
  name: "AddAndUpdateRole",
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    TableListPartnerUsers
  },
  directives: {
    Ripple,
  },
  setup() {
    const mixData = useActionsConfigRoles();
    return { ...mixData };
  },
  data() {
    return {
      required,
    };
  },
  async created() {
    await this.fetchPartnerUsers();
    await this.fetchOrgMembers();
  },
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll);
  },
  methods: {
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick();
        this.observer?.observe(this.loadInfinity);
      }
    },
    onClose() {
      this.observer?.disconnect();
    },
    handleDebounce: appUtils.debounce(function (search) {
      this.fetchOrgMembers(search);
    }, 500),
  },
};
</script>

<style>
#checkbox-partner-permissions input {
  pointer-events: none !important;
  cursor: default !important;
}

#checkbox-partner-permissions label {
  font-size: 14px;
  color: #5e5873;
}

#checkbox-partner-permissions label::before {
  pointer-events: none !important;
  cursor: default !important;
  background-color: #e2e2e2 !important;
}

#checkbox-partner-permissions .custom-control-input:checked~label.custom-control-label::before {
  background-color: #20419b !important;
}
</style>
