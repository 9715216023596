import { computed, nextTick, ref, watch } from "@vue/composition-api";

import i18n from "@/libs/i18n";
import store from "@/store";
import appUtils from "@/utils/appUtils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";
import { useToast } from "vue-toastification/composition";

export default function useConfigRoles() {
  console.clear();
  const toast = useToast();
  const initialForm = ref({
    user_id: 0,
    partner_type: 1,
    partner_role_id: 1,
  });
  const form = ref(initialForm.value);
  const addPartnerUserValidation = ref(null);
  const listMembers = ref([]);
  const metaMembers = ref({});
  const selectedMember = ref(null);
  const initLimit = ref(1000);
  const limit = ref(initLimit);
  const observer = ref(null);
  const loadInfinity = ref(null);
  const isLoading = ref(false);
  const isSubmitting = ref(false);
  const isUpdating = ref(false);
  const selectedRole = ref(null);
  const listRoles = ref([]);
  const selectedPermissions = ref([]);
  const listPermissions = ref([]);
  const reloadCount = ref(0);

  const listPartnerUsersRaw = ref([]);
  const listPartnerUsers = ref([]);
  const listPartnerUsersFields = ref([
    {
      key: "name",
      label: i18n.t("lbl_fullname"),
    },
    {
      key: "role_description",
      label: i18n.t("Vai trò"),
    },
    {
      key: "actions",
      label: i18n.t(""),
    },
  ]);
  const filter = ref({
    searchQuery: "",
    from_date: "",
    to_date: "",
    returnResultStatus: "",
  });
  const pagination = ref({
    perPage: 10,
    currentPage: 1,
    totals: 0,
    total_page: 0,
  });

  const listPermissionsRaw = computed(
    () => store.getters["authenticate/listPermissions"]
  );

  watch(
    listPermissionsRaw,
    (data) => {
      listPermissions.value = data?.map((permission) => ({
        permission_id: permission.id,
        permission_info: {
          description: permission.description,
        },
      }));
    },
    {
      immediate: true,
    }
  );

  const listRolesRaw = computed(() => store.getters["authenticate/listRoles"]);

  watch(
    listRolesRaw,
    (data) => {
      listRoles.value = data?.map((role) => ({
        id: role.id,
        role_id: role.id,
        role_name: role.role_name,
        role_description: role.role_description,
        role_summary: role.role_summary,
      }));
    },
    {
      immediate: true,
    }
  );

  const fetchOrgMembers = async (search = "") => {
    const userData = appUtils.getLocalUser();
    const orgId = userData?.supplierInfo?.org_id;

    if (!orgId) return;

    try {
      isLoading.value = true;
      const params = {
        orgId,
        keyword: search,
        status: 1,
        page: 1,
        limit: limit.value,
      };
      const res = await Vue.prototype.$rf
        .getRequest("CommonRequest")
        .getOrgMembers(params);
      if (res?.status !== 200) return;
      const result = res.data;
      const userIds = listPartnerUsers.value?.map(
        (user) => user?.user_info?.id
      );
      const members = result?.data?.filter(
        (item) => !userIds.includes(item.user_id)
      );
      listMembers.value = members;
      metaMembers.value = result?.meta;
    } catch (error) {
      console.log(error);
    } finally {
      isLoading.value = false;
    }
  };

  const fetchListRolePermissionsByRoleId = async () => {
    const roleId = selectedRole.value?.role_id;
    if (!roleId) return;

    try {
      isLoading.value = true;
      const params = {
        roleId,
        params: null,
      };
      const res = await store.dispatch(
        "authenticate/getRolePermissions",
        params
      );
      if (!res?.data) return;
      const result = res.data?.map((item) => item.permission_id);
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      isLoading.value = false;
    }
  };

  const getCheckExistedPartnerUser = async (userIdParam) => {
    const userData = appUtils.getLocalUser();
    const supplierID = userData?.supplierInfo?.id;
    const userId = userIdParam || selectedMember?.user_id;

    if (!supplierID || !userId) return;

    try {
      isLoading.value = true;
      const params = {
        user_id: userId,
        supplier_id: supplierID,
      };
      const res = await Vue.prototype.$rf
        .getRequest("AuthRequest")
        .checkExistedPartnerUser(params);
      const result = res.data;
      return result;
    } catch (error) {
      console.log(error);
    } finally {
      isLoading.value = false;
    }
  };

  const handleChangeMember = async (value) => {
    selectedMember.value = null;
    const isExisted = await getCheckExistedPartnerUser(value?.user_id);
    if (isExisted) {
      if (addPartnerUserValidation.value) {
        addPartnerUserValidation.value.setErrors({
          [i18n.t("Chọn thành viên")]: i18n.t(
            "Thành viên đã được cấu hình vai trò"
          ),
        });
      }
      return;
    } else {
      selectedMember.value = value;
    }
  };

  const handleChangePermission = (value) => {
    selectedPermissions.value = value;
  };

  const handleChangeRole = async (value) => {
    selectedRole.value = value;
    const result = await fetchListRolePermissionsByRoleId();
    handleChangePermission(result);
  };

  const handleChangeRoleUpdate = async (value, item) => {
    selectedRole.value = value;
    const result = await fetchListRolePermissionsByRoleId();
    item.partner_role.permission_ids = result;
  };

  const hasMemberNextPage = computed(() => {
    return listMembers.value.length < metaMembers.value?.total;
  });

  const infiniteScroll = async ([{ isIntersecting, target }]) => {
    if (isIntersecting) {
      const ul = target.offsetParent;
      const scrollTop = target.offsetParent.scrollTop;
      limit.value += initLimit.value;
      await nextTick();
      ul.scrollTop = scrollTop;
      fetchOrgMembers();
    }
  };

  const resetAddForm = async () => {
    addPartnerUserValidation.value.reset();
    selectedMember.value = null;
    selectedPermissions.value = [];
    selectedRole.value = null;
    reloadCount.value++;
    await fetchPartnerUsers();
    await fetchOrgMembers();
  };

  const addPartnerUser = async () => {
    try {
      isSubmitting.value = true;
      const isValid = await addPartnerUserValidation.value.validate();
      if (isValid) {
        const userData = appUtils.getLocalUser();
        const supplierID = userData?.supplierInfo?.id;
        let partner_type = 1;

        if (
          selectedRole.value?.role_name === "processor-owner" ||
          selectedRole.value?.role_name === "processor-admin" ||
          selectedRole.value?.role_name === "processor-staff"
        ) {
          partner_type = 2;
        }

        const params = {
          partner_id: supplierID,
          partner_type,
          partner_role_id: selectedRole.value?.role_id,
          user_id: selectedMember.value?.user_id,
        };
        Vue.prototype.$rf
          .getRequest("AuthRequest")
          .addPartnerUser(params)
          .then((_) => {
            toast({
              component: ToastificationContent,
              props: {
                title: i18n.t("lbl_add_new_successfully"),
                icon: "AlertTriangleIcon",
                variant: "success",
              },
            });
            resetAddForm();
          });
      }
    } catch (error) {
      console.log(error);
    } finally {
      isSubmitting.value = false;
    }
  };

  const updatePartnerUser = async (item, formRef, callback) => {
    if (!item.id || !formRef) return;

    try {
      isUpdating.value = true;
      const isValid = await formRef?.validate();
      if (isValid) {
        const userData = appUtils.getLocalUser();
        const supplierID = userData?.supplierInfo?.id;
        let partner_type = 1;

        if (
          item.partner_role?.role_name === "processor-owner" ||
          item.partner_role?.role_name === "processor-admin" ||
          item.partner_role?.role_name === "processor-staff"
        ) {
          partner_type = 2;
        }

        const params = {
          partner_id: supplierID,
          partner_type,
          partner_role_id: item.partner_role?.role_id,
        };
        Vue.prototype.$rf
          .getRequest("AuthRequest")
          .updatePartnerUser(item.id, params)
          .then((_) => {
            toast({
              component: ToastificationContent,
              props: {
                title: i18n.t("lbl_update_product_success"),
                icon: "AlertTriangleIcon",
                variant: "success",
              },
            });
            callback();
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPartnerUsers = async () => {
    const userData = appUtils.getLocalUser();
    const supplier_id = userData?.supplierInfo?.id;
    if (!supplier_id) {
      toast({
        component: ToastificationContent,
        props: {
          title: i18n.t("Không tìm thấy thông tin Supplier"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
      return;
    }

    try {
      isLoading.value = true;
      const params = {
        page_num: pagination.value.currentPage,
        page_size: pagination.value.perPage,
        keyword: filter.value.searchQuery,
        sort_by: "id",
        order: "desc",
        supplier_id,
      };
      const res = await Vue.prototype.$rf
        .getRequest("AuthRequest")
        .getListPartnerUsers(params);
      if (!res?.data) return;
      const result = res?.data;
      const newData = result?.data?.map((item) => {
        const data = {
          ...item,
          is_edit: false,
          partner_role: {
            ...item.partner_role,
            permission_ids: item.partner_role?.role_permissions?.map(
              (per) => per.permission_id
            ),
          },
        };
        return {
          ...data,
          original_data: JSON.parse(JSON.stringify(data)),
        };
      });
      listPartnerUsersRaw.value = [...newData];
      listPartnerUsers.value = [...newData];
      pagination.value = {
        perPage: result?.page?.page_size,
        currentPage: result?.page?.page_num,
        totals: result?.page?.total,
        total_page: result?.page?.total_page,
      };
    } catch (error) {
      console.log(error);
      toast({
        component: ToastificationContent,
        props: {
          title: i18n.t("lbl_error_fetching_data"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    } finally {
      isLoading.value = false;
    }
  };

  return {
    toast,
    initialForm,
    form,
    addPartnerUserValidation,
    listMembers,
    selectedMember,
    observer,
    hasMemberNextPage,
    loadInfinity,
    isLoading,
    isSubmitting,
    isUpdating,
    selectedPermissions,
    listPermissions,
    selectedRole,
    listRoles,
    reloadCount,
    listPartnerUsersRaw,
    listPartnerUsers,
    listPartnerUsersFields,
    filter,
    pagination,
    resetAddForm,
    addPartnerUser,
    updatePartnerUser,
    handleChangeMember,
    handleChangePermission,
    handleChangeRole,
    handleChangeRoleUpdate,
    fetchOrgMembers,
    infiniteScroll,
    fetchListRolePermissionsByRoleId,
    fetchPartnerUsers,
  };
}
